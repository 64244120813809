import { useStableProps } from '@/hooks/useStable';
import StandaloneRelatedArticleSlider from 'base/components/RelatedArticleSlider';

export const FeminaStandaloneRelatedArticleSlider: typeof StandaloneRelatedArticleSlider = (props) => {
  const stableProps = useStableProps(
    {
      slider: {
        options: {
          $slider: {
            $headline: {
              className: 'text-headline-sm md:text-headline-md',
            },
            className: '!bg-transparent',
            $caption: {
              className: 'mb-0.5',
            },
          },
        },
      },
      options: {
        $arrow: {
          className: 'md:w-2 mt-0.75 md:mt-1.5 ml-2',
        },
      },
    },
    props,
  );

  return <StandaloneRelatedArticleSlider {...stableProps} />;
};

export default FeminaStandaloneRelatedArticleSlider;
