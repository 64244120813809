/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "text-black",
  "headline_variant_default": "",
  "headline_size_default": "text-headline-md sm:text-headline-lg md:text-headline-xl",
  "slide_caption_variant_default": "capitalize",
  "slide_caption_colors_default": "text-primary-300",
  "slide_caption_size_default": "text-body-sm",
  "slide_description_colors_default": "text-black",
  "slide_description_variant_default": "",
  "slide_description_size_default": "text-headline-sm pt-3 sm:pl-12 sm:text-headline-md",
  "slide_group_variant_default": "[counter-reset:most-read] flex-nowrap flex-row flex overflow-x-auto overflow-y-hidden scrollbar-thin snap-x relative justify-between",
  "slide_group_colors_default": "scrollbar-thumb-linen-400",
  "slide_group_size_default": "gap-3 pb-3 px-0",
  "slide_image_variant_default": "after:absolute after:bottom-0 after:content-[counter(most-read)] after:flex after:items-center after:justify-center relative [&_img]:rounded-none after:rounded-none after:translate-y-1/3 after:right-3",
  "slide_image_colors_primary": "after:bg-linen-100 after:text-black",
  "slide_image_size_default": "after:h-10.5 after:w-8 after:text-headline-xl sm:after:left-0 sm:after:bottom-0 sm:after:translate-y-[calc(100%+18px)] sm:after:text-headline-2xl",
  "slide_variant_default": "[counter-increment:most-read] flex-col flex grow relative snap-center flex-1",
  "slide_colors_default": "",
  "slide_size_default": "gap-2.5 basis-0 min-w-61 sm:min-w-75 sm:gap-4.5",
  "colors_default": "bg-linen-100 wings-linen-100",
  "variant_default": "flex-col flex overflow-hidden wings z-0",
  "size_default": "w-full gap-3 p-0"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "MostRead");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;